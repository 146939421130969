import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../Utils/Title";
import { useNavigate } from "react-router-dom";
export default function FormExchangeOrder({
  status,
  closeFormConfirm,
  loadData,
  itemChoose,
}) {
  let [inputInfo, setInputInfo] = useState({
    quantity: -1,
    product: -1,
    note: "",
  });

  let [exchangeList, setExchangeList] = useState([]);
  console.log("exchangeList", exchangeList);
  let navigate = useNavigate();

  //  { fish_id: item.Fish.fish_id, size_id: item.Size.size_id }
  const OptionArray = () => {
    return itemChoose.OrderDetails.map((item, index) => {
      return (
        <option key={index} value={index}>
          {" "}
          {item.Fish.fish_name}, Size: {item.Size.size_name}, Đã mua:{" "}
          {item.amount}{" "}
        </option>
      );
    });
  };

  const handleAddExchangeList = () => {
    if (inputInfo.product === -1) {
      notify(false, "Vui lòng chọn sản phẩm đổi trả.");
      return;
    }
    if (!itemChoose.OrderDetails[inputInfo.product]) {
      notify(false, "Sản phẩm đổi trả không tồn tại trong đơn hàng.");
      return;
    }
    if (inputInfo.quantity === -1 || inputInfo.quantity === 0) {
      notify(false, "Vui lòng nhập số lượng đổi trả lớn hơn 0");
      return;
    }
    if (
      inputInfo.quantity > itemChoose.OrderDetails[inputInfo.product].amount
    ) {
      notify(false, "Số lượng đổi trả đang lớn hơn số lượng đã mua.");
      return;
    }
    const check = exchangeList.findIndex(
      (item) =>
        item.fish_id ===
          itemChoose.OrderDetails[inputInfo.product].Fish.fish_id &&
        item.size_id === itemChoose.OrderDetails[inputInfo.product].Size.size_id
    );
    if (check === -1) {
      exchangeList.push({
        fish_name: itemChoose.OrderDetails[inputInfo.product].Fish.fish_name,
        size_name: itemChoose.OrderDetails[inputInfo.product].Size.size_name,
        fish_id: itemChoose.OrderDetails[inputInfo.product].Fish.fish_id,
        size_id: itemChoose.OrderDetails[inputInfo.product].Size.size_id,
        quantity: inputInfo.quantity,
      });
      notify(true, "Thêm sản phẩm đổi trả thành công.");
      setExchangeList([...exchangeList]);
    } else {
      exchangeList[check].quantity = inputInfo.quantity;
      notify(true, "Cập nhật số lượng sản phẩm đổi trả thành công.");
      setExchangeList([...exchangeList]);
    }
  };

  const handleRemoveExchangeList = (fish_id, size_id) => {
    const index = exchangeList.findIndex(
      (item) => item.fish_id === fish_id && item.size_id === size_id
    );
    if (index === -1) {
      notify(false, "Không tìm thấy sản phẩm đổi trả trong danh sách.");
    } else {
      exchangeList.splice(index, 1);
      setExchangeList([...exchangeList]);
      notify(true, "Xóa sản phẩm khỏi danh sách đổi trả thành công");
    }
  };
  const handleEvent = async () => {
    if (exchangeList.length === 0) {
      notify(false, "Vui lòng chọn và thêm ít nhất 1 sản phẩm.");
      return;
    }
    if (status === "customer_exchange_order") {
      const response = await axios.put(
        `${ApiLink.domain + "/order/exchange/" + itemChoose.order_id}`,
        {
          note: inputInfo.note.trim(),
          exchangeList,
        },
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        notify(true, response.data.message);
        loadData(response.data.data);
        closeFormConfirm();
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          return navigate("/account/login", { replace: true });
        } else if (response.data.must === "permission") {
          return navigate("/", { replace: true });
        }
      }
    }
  };

  const handleCloseEvent = () => {
    closeFormConfirm();
  };
  console.log(inputInfo);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "note") {
      setInputInfo({ ...inputInfo, [name]: value });
    } else setInputInfo({ ...inputInfo, [name]: Number(value) });
  };

  return (
    <div className="confirm_container_800 remove_customer_class bg-white">
      <div
        className="confirm_header text-white bg-primary"
        style={{ padding: 10, fontWeight: 700 }}
      >
        <i className="fas fa-check-circle" style={{ color: "#47f764" }} />
        <span style={{ marginLeft: 3 }}>Xác nhận đổi trả hàng</span>
      </div>
      <div
        className="confirm_content"
        style={{
          padding: 10,
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div style={{ flex: 5, marginRight: 10 }}>
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Chọn sản phẩm:</label>
            <select
              className="form-select"
              name="product"
              defaultValue={-1}
              onInput={(e) => handleChange(e)}
            >
              <option value={-1}>Vui lòng chọn sản phẩm</option>
              {OptionArray()}
            </select>
          </div>
        </div>

        <div style={{ flex: 2, marginLeft: 10 }}>
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Số lượng trả:</label>
            <input
              className="form-control showordisable"
              placeholder="Số lượng trả"
              type="number"
              name="quantity"
              onInput={(e) => handleChange(e)}
            ></input>
          </div>
        </div>

        <div
          style={{
            flex: 2,
            marginLeft: 10,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button
            className="btn btn-success me-1 mb-2 btn_xacnhan_xoa"
            style={{ margin: "0px 10px", padding: " 5px 10px" }}
            type="button"
            onClick={() => handleAddExchangeList()}
          >
            Thêm
          </button>
        </div>
      </div>
      <div
        className="table-responsive pt-3"
        style={{ paddingLeft: "10px", paddingRight: 10 }}
      >
        <table className="table table-bordered text-center">
          <thead>
            <tr>
              <th>Tên sản phẩm</th>
              <th>Size</th>
              <th>Số lượng trả</th>
              <th>Xóa</th>
            </tr>
          </thead>
          <tbody>
            {exchangeList.map((item, index) => (
              <tr key={index} className="table-white">
                <td>{item.fish_name}</td>
                <td>{item.size_name}</td>
                <td>{item.quantity}</td>
                <td>
                  <button
                    className="btn btn-danger me-1 mb-2 btn_huy_xoa"
                    style={{ margin: "0px 10px", padding: "5px 10px" }}
                    type="button"
                    onClick={() =>
                      handleRemoveExchangeList(
                        Number(item.fish_id),
                        Number(item.size_id)
                      )
                    }
                  >
                    Xóa
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div style={{ paddingLeft: 10, paddingRight: 10, paddingBottom: 5 }}>
        <div>
          <label className="form-label">Nội dung trả hàng</label>
          <textarea
            onChange={(e) => handleChange(e)}
            className="form-control"
            name="note"
            type="text"
            rows="2"
            defaultValue={inputInfo.note}
            placeholder="Tôi muốn trả lại hàng để mua sản phẩm khác. Ghi thêm số tài khoản, tên ngân hàng nếu bạn muốn nhận hoàn tiền."
          />
        </div>
        <div style={{ fontSize: 13 }}>
          Lưu ý: Chỉ chấp nhận đổi trả khi sản phẩm còn nguyên vẹn, cá còn sống.
          Sau khi gửi yêu cầu đổi trả, bạn cần gửi hàng tới địa chỉ{" "}
          <strong>
            Đức Ngọc Fish Store, số 97 Đ. Man Thiện, Hiệp Phú TP.HCM
          </strong>
          .
        </div>
      </div>
      <div className="confirm_buttons">
        <div id="formDelete">
          <button
            onClick={() => handleEvent()}
            className="btn btn-success me-1 mb-2 btn_xacnhan_xoa"
            style={{ margin: "0px 10px", padding: "7px 20px" }}
            type="button"
          >
            {status === "admin_add_coupon" ? "Thêm" : "Lưu"}
          </button>
        </div>
        <button
          onClick={() => handleCloseEvent()}
          className="btn btn-danger me-1 mb-2 btn_huy_xoa"
          style={{ margin: "0px 10px", padding: "7px 20px" }}
          type="button"
        >
          Hủy
        </button>
      </div>
    </div>
  );
}
