import React, { useState } from "react";
import axios from "axios";
import { ApiLink, notify } from "../../../../Utils/Title";
import { useNavigate } from "react-router-dom";
export default function FormEditOrder({
  status,
  closeFormConfirm,
  loadData,
  itemChoose,
}) {
  let [inputOrder, setInputOrder] = useState({
    phonenumber: itemChoose.phonenumber,
    fullname: itemChoose.fullname,
    address: itemChoose.address,
    note: itemChoose.note,
    order_status: itemChoose.order_status,
  });
  let [exchangeList, setExchangeList] = useState(
    itemChoose.OrderDetails.map((item) => {
      return {
        fish_name: item.Fish.fish_name,
        size_name: item.Size.size_name,
        fish_id: item.fish_id,
        size_id: item.size_id,
        amount: item.amount,
        exchange: item.exchange,
        stock: item.stock,
      };
    })
  );

  console.log("exchangeList", exchangeList);

  const changeStockExchange = (e, item) => {
    const { value } = e.target;
    const find_index = exchangeList.findIndex(
      (element) =>
        element.fish_id === item.fish_id && element.size_id === item.size_id
    );
    if (find_index !== -1) {
      exchangeList[find_index].stock = Number(value);
      setExchangeList([...exchangeList]);
    }
  };

  const checkStockInput = () => {
    let check = true;
    let message = "";
    exchangeList.forEach((item) => {
      if (item.stock < 0) {
        message +=
          "Số lượng nhập kho sản phẩm " + item.fish_name + " đang nhỏ hơn 0. ";
        check = false;
      }
      if (item.stock > item.exchange) {
        message +=
          "Số lượng nhập kho sản phẩm " +
          item.fish_name +
          " lớn hơn số lượng khách đổi trả hàng. ";
        check = false;
      }
    });
    if (!check) {
      notify(false, message);
    }
    return check;
  };
  let navigate = useNavigate();
  const handleEvent = async () => {
    if (status === "user_edit_order") {
      const orderStatus = {
        order_id: itemChoose.order_id,
        order_status: itemChoose.order_status, // 4: Yêu cầu hủy
        phonenumber: inputOrder.phonenumber,
        fullname: inputOrder.fullname,
        address: inputOrder.address,
        note: inputOrder.note,
      };
      const response = await axios.post(
        `${ApiLink.domain + "/order/user"}`,
        orderStatus,
        {
          withCredentials: true,
        }
      );
      if (response.data.status) {
        notify(true, response.data.message);
        loadData(response.data.data);
        closeFormConfirm();
      } else {
        notify(false, response.data.message);
        if (response.data.must === "login") {
          window.localStorage.clear();
          return navigate("/account/login", { replace: true });
        }
      }
    } else if (status === "admin_edit_order") {
      const check_stock = checkStockInput();
      if (check_stock) {
        const order = {
          order_status: Number(inputOrder.order_status),
          phonenumber: inputOrder.phonenumber,
          fullname: inputOrder.fullname,
          address: inputOrder.address,
          note: inputOrder.note,
          exchangeList: exchangeList,
        };
        const response = await axios.put(
          `${ApiLink.domain + "/admin/order/" + itemChoose.order_id}`,
          order,
          {
            withCredentials: true,
          }
        );
        if (response.data.status) {
          notify(true, response.data.message);
          loadData(response.data.data);
          closeFormConfirm();
        } else {
          notify(false, response.data.message);
          if (response.data.must === "login") {
            window.localStorage.clear();
            return navigate("/", { replace: true });
          } else if (response.data.must === "permission") {
            return navigate("/", { replace: true });
          }
        }
      }
    }
  };

  const handleCloseEvent = () => {
    closeFormConfirm();
  };

  const handleChangeOrder = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    setInputOrder({ ...inputOrder, [name]: value });
  };

  return (
    <div
      className={
        itemChoose.order_status === 7 ||
        itemChoose.order_status === 8 ||
        itemChoose.order_status === 9 ||
        itemChoose.order_status === 10 ||
        itemChoose.order_status === 11
          ? "confirm_container_900 remove_customer_class bg-white"
          : "confirm_container remove_customer_class bg-white"
      }
      style={{
        overflow: "auto",
        maxHeight: "65vh",
      }}
    >
      <div
        className="confirm_header text-white bg-primary"
        style={{ padding: 10, fontWeight: 700 }}
      >
        <i className="fas fa-check-circle" style={{ color: "#47f764" }} />
        <span style={{ marginLeft: 3 }}>Xác Nhận</span>
      </div>
      <div className="split_class" style={{ display: "flex" }}>
        <div
          className="confirm_content"
          style={{ padding: 10, textAlign: "center", minWidth: 350 }}
        >
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Họ tên người nhận:</label>
            <input
              className="form-control showordisable"
              placeholder="Họ tên người nhận"
              type="text"
              name="fullname"
              defaultValue={inputOrder.fullname}
              onInput={(e) => handleChangeOrder(e)}
            ></input>
          </div>
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Số điện thoại:</label>
            <input
              className="form-control showordisable"
              placeholder="Số điện thoại"
              type="text"
              defaultValue={inputOrder.phonenumber}
              name="phonenumber"
              onInput={(e) => handleChangeOrder(e)}
            ></input>
          </div>
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Địa chỉ nhận hàng:</label>
            <input
              className="form-control showordisable"
              placeholder="Địa chỉ nhận hàng"
              defaultValue={inputOrder.address}
              name="address"
              onInput={(e) => handleChangeOrder(e)}
            ></input>
          </div>
          <div style={{ textAlign: "left" }}>
            <label className="form-label">Ghi chú:</label>
            <textarea
              className="form-control showordisable"
              placeholder="Ghi chú nếu có"
              defaultValue={inputOrder.note}
              name="note"
              rows={1}
              onInput={(e) => handleChangeOrder(e)}
            ></textarea>
          </div>
          {status === "admin_edit_order" ? (
            <div style={{ textAlign: "left" }}>
              <label className="form-label">Trạng thái đơn hàng:</label>

              {itemChoose.order_status >= 0 && itemChoose.order_status <= 6 ? (
                <select
                  name="order_status"
                  className="form-select"
                  defaultValue={inputOrder.order_status}
                  onInput={(e) => handleChangeOrder(e)}
                >
                  <option value={1}>Đặt hàng</option>
                  <option value={2}>Đã xác nhận</option>
                  <option value={3}>Đang vận chuyển</option>
                  <option value={4}>Yêu cầu hủy</option>
                  <option value={5}>Giao hàng thành công</option>
                  <option value={6}>Đã hủy đơn</option>{" "}
                </select>
              ) : itemChoose.order_status === 7 ? (
                <select
                  name="order_status"
                  className="form-select"
                  defaultValue={inputOrder.order_status}
                  onInput={(e) => handleChangeOrder(e)}
                >
                  <option value={7}>Đã yêu cầu trả toàn bộ</option>
                  <option value={8}>Trả toàn bộ thành công</option>
                </select>
              ) : itemChoose.order_status === 9 ? (
                <select
                  name="order_status"
                  className="form-select"
                  defaultValue={inputOrder.order_status}
                  onInput={(e) => handleChangeOrder(e)}
                >
                  <option value={9}>Yêu cầu đổi hàng</option>
                  <option value={10}>Đổi trả hàng thành công</option>
                  <option value={11}>Đổi trả hàng thất bại</option>
                </select>
              ) : itemChoose.order_status === 8 ||
                itemChoose.order_status === 10 ||
                itemChoose.order_status === 11 ? (
                <select
                  name="order_status"
                  className="form-select"
                  defaultValue={inputOrder.order_status}
                  disabled
                >
                  {itemChoose.order_status === 8 ? (
                    <option value={-1}>Trả toàn bộ thành công.</option>
                  ) : itemChoose.order_status === 10 ? (
                    <option value={-1}>Đổi trả hàng thành công.</option>
                  ) : itemChoose.order_status === 11 ? (
                    <option value={-1}>Đổi trả hàng thất bại.</option>
                  ) : (
                    ""
                  )}
                </select>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
        {itemChoose.order_status === 7 ||
        itemChoose.order_status === 8 ||
        itemChoose.order_status === 9 ||
        itemChoose.order_status === 10 ||
        itemChoose.order_status === 11 ? (
          <div
            style={{
              width: "100%",
              marginTop: "15px",
              padding: "0px 10px",
            }}
          >
            <table className="table table-bordered text-center">
              <thead>
                <tr style={{ fontSize: 13 }}>
                  <th>Tên sản phẩm</th>
                  <th>Size</th>
                  <th>SL mua</th>
                  <th>SL trả</th>
                  <th>Nhập kho</th>
                </tr>
              </thead>
              <tbody>
                {exchangeList.map((item, index) => (
                  <tr key={index} className="table-white">
                    <td>{item.fish_name}</td>
                    <td>{item.size_name}</td>
                    <td>{item.amount}</td>
                    <td>{item.exchange}</td>
                    {itemChoose.order_status === 8 ||
                    itemChoose.order_status === 10 ||
                    itemChoose.order_status === 11 ? (
                      <td>{item.stock}</td>
                    ) : (
                      <td style={{ width: 100 }}>
                        <input
                          className="form-control showordisable"
                          placeholder="Nhập vào kho"
                          type="number"
                          name="stock"
                          defaultValue={item.stock}
                          onInput={(e) => changeStockExchange(e, item)}
                        ></input>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="confirm_buttons">
        <div id="formDelete">
          {itemChoose.order_status === 8 ||
          itemChoose.order_status === 10 ||
          itemChoose.order_status === 11 ? (
            ""
          ) : (
            <button
              onClick={() => handleEvent()}
              className="btn btn-success me-1 mb-2 btn_xacnhan_xoa"
              style={{ margin: "0px 10px", padding: "5px 20px" }}
              type="button"
            >
              Xác Nhận
            </button>
          )}
        </div>
        <button
          onClick={() => handleCloseEvent()}
          className="btn btn-danger me-1 mb-2 btn_huy_xoa"
          style={{ margin: "0px 10px", padding: "5px 20px" }}
          type="button"
        >
          {itemChoose.order_status === 8 ||
          itemChoose.order_status === 10 ||
          itemChoose.order_status === 11
            ? "Đóng"
            : "Hủy"}
        </button>
      </div>
    </div>
  );
}
